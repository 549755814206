import { createApp } from 'vue'
import App from './App.vue'
import { executeOnPageLoad } from './fetchRequestLogic.js'; // 引入fetch请求逻
import { TiRow, TiCol, TiNavHeader, TiButton, TiModal } from "tinorth-vue/lib/tinorth-vue.js";
import 'tinorth-vue/lib/styles/common.css';
import 'tinorth-vue/lib/styles/color.css';

const components = [
    TiRow, TiCol, TiNavHeader, TiButton, TiModal
]

const app = createApp(App)
components.forEach(component => {
    app.component(component.name, component)
})
app.mount('#app');

// 页面加载完成后执行请求逻辑
executeOnPageLoad();

