<template>
  <ti-nav-header style="padding: 0 10%; align-items: center" :logo="logo">
    <ti-button class="t-button arco-btn-primary" @click="modalVis = true"
      >捐赠</ti-button
    >
  </ti-nav-header>
  <div class="data-area" v-if="data.length > 0">
    <ti-row class="data-list">
      <ti-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="6"
        :xl="6"
        v-for="(item, index) in data"
        :key="index"
        class="data-item"
      >
        <a target="_blank" :href="item.url" class="data-card">
          <div class="data-main">
            <img class="data-icon" :src="item.icon" alt="" />
            <span class="data-title">{{ item.title }}</span>
          </div>
          <span class="data-desc">{{ item.desc }}</span>
        </a>
      </ti-col>
    </ti-row>
  </div>
  <div class="data-area" v-else>
    <h2 class="no-data">暂无数据</h2>
  </div>

  <ti-modal :visible="modalVis" style="">
    <div class="model-area">
      <h2>微信扫一下，送一杯咖啡 &#x2615;</h2>

      <img class="scaled-img" :src="reward" alt="" />

      <ti-button class="t-button" @click="modalVis = false">关闭</ti-button>
    </div>
  </ti-modal>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      logo: {
        url: "logo.jpg",
        alt: "VastWain",
      },
      data: [],
      modalVis: false,
      reward: "reward_qrcode.png",
    };
  },
  mounted() {
    document.title = "VastWain - 导航服务";

    let url = "data.json";

    if (location.pathname === "/wain") {
      url = "wain.json";
    }

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          return [];
        }
        return response.json();
      })
      .then((response) => {
        this.data = response;
      })
      .catch((error) =>
        console.error("There was a problem with your fetch operation:", error)
      );

    navigator.sendBeacon(
      "https://report.nihaoshijie.com.cn/rapi/report/create?code=757794"
    );
  },
};
</script>

<style>
.ti-nav-header .ti-nav-header-container {
  justify-content: flex-end !important;
}

.ti-nav-header .ti-nav-header-container .ti-nav-header-menu {
  padding: 12px 0;
}

.data-area {
  padding-top: 36px;
  position: relative;
  min-height: 100%;
  background-color: #f7f7f7;
}

.data-list {
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.data-item {
  position: relative;
  transition: All 0.3s ease-in-out;
  -webkit-transition: All 0.3s ease-in-out;
  -moz-transition: All 0.3s ease-in-out;
  -o-transition: All 0.3s ease-in-out;
}

/* 调整scale的值控制动画的大小 */
.data-item:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
}

.data-card {
  display: block;
  position: relative;
  min-height: 180px;
  padding: 10px 4px 0;
  margin: 0 3% 5% 3%;
  border-radius: 3px;
  justify-content: center;
  color: #333333;
  background-color: #fff;
}

.data-main {
  width: 100%;
  height: 56px;
  padding: 0 20px;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.data-icon {
  position: relative;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.data-title {
  line-height: 32px;
  font-size: 16px;
  color: #05060a;
  font-weight: 900;
  border-bottom: 1px solid #e7eaef;
}

.data-desc {
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  font-size: 14px;
  color: #05060a;
  line-height: 28px;
  vertical-align: middle;
  word-break: break-all;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.model-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scaled-img {
  max-width: 200px; /* 设置最大宽度 */
  max-height: 150px; /* 设置最大高度 */
  width: auto; /* 宽度自适应 */
  height: auto; /* 高度自适应 */
}
</style>
