/**
 * 异步发起fetch请求
 * @param {string} url 请求地址
 * @param {object} [options] fetch选项，如headers、method等
 * @returns {Promise<any>} 返回Promise，解析为响应结果
 */
function fetchAsync(url, options = {}) {
  return fetch(url, options).then((response) => {
    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}: ${response.status}`);
    }
    return response.json();
  });
}

/**
 * 在Vue3页面加载时执行的请求逻辑
 */
async function executeOnPageLoad() {
  const firstFetchUrl = "https://ipwho.is/";
  const secondFetchUrl = "https://vastwain.xyz/devops/monitor/report?code=891394";

  // 第一次fetch请求，独立处理错误
  let firstResultForSecondRequest;
  try {
    firstResultForSecondRequest = await fetchAsync(firstFetchUrl);
  } catch (firstError) {
    console.error("Error executing first fetch request:", firstError);
    // No-op, firstResult might not be defined due to the first fetch failure
  }

  // 获取当前页面URI
  const currentPageUri = window.location.pathname;

  const browserInfo = getBrowserAndOSInfo();

  // 第二次fetch请求，将第一次请求结果（可能为undefined）和当前页面URI作为参数
  const secondFetchParams = {
    ip: firstResultForSecondRequest?.ip ?? '',
    city: firstResultForSecondRequest?.city ?? '',
    ipJson: JSON.stringify(firstResultForSecondRequest?? {}),
    currentPageUri,
    browserInfo
  };

  const secondOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(secondFetchParams),
  };

  // 第二次fetch请求，独立处理错误
  try {
    await fetchAsync(secondFetchUrl, secondOptions);
    console.log("Both fetch requests completed successfully.");
  } catch (secondError) {
    console.error("Error executing second fetch request:", secondError);
  }
}

// 页面加载完成后执行请求逻辑
if (
  document.readyState === "complete" ||
  (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  executeOnPageLoad();
} else {
  document.addEventListener("DOMContentLoaded", executeOnPageLoad);
}

/**
   * fetchAsync 函数封装了基本的fetch请求逻辑，返回一个Promise对象，用于处理异步操作。
executeOnPageLoad 函数定义了您所需的请求逻辑：
发起第一次fetch请求到firstFetchUrl，获取结果并存储在变量firstResult中。
使用window.location.href获取当前页面URI。
准备第二次fetch请求的参数，包括firstResult和currentPageUri。
发起第二次fetch请求到secondFetchUrl，使用POST方法发送JSON格式的参数。
请求成功或失败时，分别打印相应的日志信息。
最后，根据页面加载状态决定何时执行executeOnPageLoad函数：
如果页面已完全加载（document.readyState === 'complete'）或处于非加载状态（document.readyState !== 'loading' && !document.documentElement.doScroll），直接调用executeOnPageLoad。
否则，监听DOMContentLoaded事件，在页面DOM加载完成时触发executeOnPageLoad。
这段代码实现了在Vue3页面加载时异步执行fetch请求，且不会阻塞页面加载。请将上述代码保存为fetchRequestLogic.js，并在Vue3项目的适当位置（如main.js或相关组件中）引入并执行。
主要优化点如下：
将第一次fetchAsync调用放入独立的try-catch块中，捕获并单独处理其可能出现的错误，确保其失败不会影响后续代码执行。
在发起第二次fetchAsync请求前，尝试获取第一次请求的结果（可能为undefined）。由于已经对第一次请求的错误进行了独立处理，此处无需再次捕获异常。
将第二次fetchAsync调用放入另一个独立的try-catch块中，同样独立处理其可能出现的错误。
通过以上优化，即使第一个接口请求失败，也不会影响第二个接口的请求。两个接口的错误信息分别被捕获并打印到控制台。
   */

function getBrowserAndOSInfo() {
    const userAgent = navigator.userAgent;
  
    // 初步解析 User-Agent 字符串
    const info = {
      browser: '',
      version: '',
      os: ''
    };
  
    // 检测浏览器类型和版本
    const browserMatch = userAgent.match(/(opera|chrome|safari|firefox|msie|trident|edge)\/?\s*(\.?\d+(\.\d+)*)/i);
    if (browserMatch) {
      info.browser = browserMatch[1].toLowerCase();
      info.version = browserMatch[2];
    }
  
    // 检测操作系统
    const osMatch = userAgent.match(/\(([^();]+);([^()]*)/);
    if (osMatch) {
      info.os = osMatch[1].replace(/_/g, ' ').toLowerCase();
    }
  
    return info;
  }
